import { Steps } from "antd";
import apiCaller from "utils/apiCaller";
import React, { useEffect, useState } from "react";
import { StepperProps } from "models";

const { Step } = Steps;

const Stepper = ({
  workflowId,
  workflowSteps,
  allStepsInfo,
  users,
  currentStep,
  currAssignee,
  usersMap,
  wfUsers,
  logActions,
  PdfPreviewProp,
  showWfStepsStatus,
}: StepperProps) => {
  const [formValues,setFormValues]=useState<any>();
  // const [users,setUsers]=useState<any>();

  useEffect(() => {
    async function checkFormvalues(workflowId:any) {
      const resp = await apiCaller.get("/api/all-form-data", {
        params: {
          workflowId,
        },
      });
      for (const data in resp.data){
        let data1=resp.data[data]
        if (data1.step==allStepsInfo?.['begin']){
          setFormValues(data1)
        }
        }
      }
    checkFormvalues(workflowId)
  },[])


  let showPdfPreview = true;
  if (currentStep === "generate_pdf" || currentStep === "complete") showPdfPreview = false;

  let currentStepNumber = 0;
  currAssignee = usersMap[currAssignee];

  let wfCreator = "";

  if (wfUsers !== null && wfUsers !== undefined) {
    wfCreator = usersMap[wfUsers[0]];
  }

  if (showWfStepsStatus === false) return <></>;

  // Filter only visible steps
  const visibleSteps =
    workflowSteps?.filter((step: any) => {
      const stepInfo = allStepsInfo?.[step?.step];
      return stepInfo?.visible !== false; // Only include steps with visible == undefined or true
    }) || [];

  // Check if the current step is visible
  let isCurrentStepVisible = currentStep !== undefined && allStepsInfo?.[currentStep]?.visible !== false;

  // Find the current step number based on visible steps
  if (currentStep === "ForcePushToSecondStep") {
    currentStepNumber = 2;
  } else if (isCurrentStepVisible) {
    // If the current step is visible, use its index from visibleSteps
    for (let i = 0; i < visibleSteps.length; i++) {
      if (visibleSteps[i]["step"] === currentStep) {
        currentStepNumber = i;
        break;
      }
    }
  } else {
    // If the current step is not visible, find the index of the last visible step before it
    for (let i = visibleSteps.length - 1; i >= 0; i--) {
      const visibleStep = visibleSteps[i];
      const currentStepIndex = workflowSteps.findIndex((step: any) => step["step"] === currentStep);
      const visibleStepIndex = workflowSteps.findIndex((step: any) => step["step"] === visibleStep["step"]);

      if (visibleStepIndex <= currentStepIndex) {
        currentStepNumber = i; // Set the current step to the last visible step before or equal to the current step
        break;
      }
    }
  }
  const getUsers = (netid:any) => {
    for(let i in users){
      if(users[i].netId==netid && netid!=""){
        return "To be done by "+ users[i].firstName+" "+users[i].lastName;
      }
    }

  }

  const CSSForStepper = (createTimestamp: string, wfCreator: string) => {
    const divStyle = { fontSize: "10px", margin: "0px", padding: "0px" };
    const margin = { margin: "0px", padding: "0px" };
    const text = wfCreator == null ? "Done" : "Done by " + " " + wfCreator;
    const lastIndex = createTimestamp?.lastIndexOf(":");
    const afterSlice = "(" + createTimestamp.slice(0, lastIndex) + " " + createTimestamp.slice(-2) + ")";
    return (
      <div>
        <span style={margin}>{text}</span> <span style={divStyle}>{afterSlice}</span>
      </div>
    );
  };

  const CSSforForm = (step:any) => {
    const margin = { margin: "0px", padding: "0px" };
    let text1=allStepsInfo?.[step?.step]?.params?.assignee?.split(":")[2];
    let output=formValues?formValues?.[text1]:null; 
    const text = ((allStepsInfo?.[step?.step]?.function=='ASSIGN' || allStepsInfo?.[step?.step]?.function=='FORM') && output !=null)? output:"";   
    return (
      <div>
        <span style={margin}>{getUsers(text.split('@')[0])}</span>
      </div>
    );
  };

  return (
    <>
      <Steps direction="vertical" current={currentStepNumber}>
        {visibleSteps.map((step: any, index: any) => {
          if (step === null) return null;

          let defaultActor: string = wfCreator;
          let desc: any = "";

          // For the first step
          if (index === 0) {
            let createTimestamp = logActions["createTimestamp"];
            if (wfCreator !== "") {
              if (createTimestamp === undefined) {
                desc = "Done by " + " " + wfCreator;
              } else {
                desc = CSSForStepper(createTimestamp, wfCreator);
              }
            }
          }
          // For the current step
          else if (index === currentStepNumber && currAssignee && currAssignee !== "") {
            desc = "To be done by" + " " + currAssignee;
          }
          // For previous steps
          else if (index < currentStepNumber) {
            const actor = logActions[step["step"]];
            let actorEmail: string = "";
            let timestamp: string = "";

            if (actor === undefined) {
              if (defaultActor !== "") desc = "Done by " + defaultActor;
            } else {
              actorEmail = actor["actor"];
              timestamp = actor["timestamp"];
              desc = CSSForStepper(timestamp, usersMap[actorEmail]);
              defaultActor = usersMap[actorEmail];
            }
          }
          else{
            desc=CSSforForm(step);
          }
          return <Step key={index} title={step["stepNls"].replace("|", "")} description={desc} />;
        })}
      </Steps>
      {showPdfPreview ? PdfPreviewProp : null}
    </>
  );
};

export default Stepper;
