import { useEffect, useState } from "react";
import download from "downloadjs";
import apiCaller from "utils/apiCaller";

import { DownloadOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";


// import { getStorage } from "firebase/storage";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function WorkflowFileDisplay({workflowId, workflowStepWithDesiredDocument, workflow, retrieve}: any){
    const [file, setFile] = useState("");
    const [numPages, setNumPages] = useState<any | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingFlag, setLoadingFlag] = useState<boolean>(true);
    useEffect(() => {
        if (workflow) {
          if (retrieve) {
            retrievePDF();
          }
        }
      }, []);
    const downloadPDF = () => {
        const userId = workflow?.createdBy?.split("@")?.[0];
        const filename = [workflow.workflowFile, userId !== undefined ? `- ${userId}` : ""].join();
        download(file, `${filename}.pdf`, "application/pdf");
    };
    
      
    const retrievePDF = async () => {
      // Can try making a parameter in this component that specifies the endpoint to make this request more generic
        const getUploadUrl = await apiCaller.get(`/api/uploaded-file`, {
            params: {
              workflowId,
              workflowStepWithDesiredDocument,
            },
        });
        let url = getUploadUrl.data.url
        const getFileFromUrl = await apiCaller.get(url, { responseType: "arraybuffer" });
        if (getFileFromUrl.status === 200) {
            
            const rawPdfData = getFileFromUrl.data
            const pdfBlob = new Blob([rawPdfData], { type: "application/pdf" });

            var reader = new FileReader();
            reader.onloadend = function () {
                var base64data = reader.result;
                setFile(base64data as string);
            };
            reader.readAsDataURL(pdfBlob);
        } else {
        setLoadingFlag(false);
        }
        
        
    };

    const changePage = (offset: number) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
      };
    
      const previousPage = () => {
        changePage(-1);
      };
    
      const nextPage = () => {
        changePage(1);
      };
    return(
        <div
      className="container"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: 25,
        overflow: "auto",
      }}
    >
      {file !== "" ? (
        <>
          <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={downloadPDF}>
            Download PDF
          </Button>
          <div style={{border:"1px solid black",marginTop:"10px"}}>

          <Document
            file={file}
            onLoadError={(error) => alert("Error while loading document! " + error.message)}
            onSourceError={(error) => alert("Error while loading document! " + error.message)}
            onLoadSuccess={({ numPages }) => {
                setNumPages(numPages);
                setPageNumber(1);
            }}
            >
            {<Page width={650} pageNumber={pageNumber} />}
          </Document>
            </div>
          <p>
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </p>
          <div
            style={{
              paddingBottom: 20,
            }}
          >
            <Button type="default" disabled={pageNumber <= 1} onClick={previousPage}>
              <LeftOutlined />
              Previous Page
            </Button>
            <Button type="default" disabled={pageNumber >= numPages} onClick={nextPage}>
              Next Page <RightOutlined />
            </Button>
          </div>
        </>
      ) : (
        <>
          {(() => {
            if (loadingFlag) {
              return <div>Loading...</div>;
            } else {
              return <div>No Agreement Created</div>;
            }
          })()}
        </>
      )}
    </div>
    )
}

export default WorkflowFileDisplay;