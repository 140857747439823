import { Input, Button, Card, TreeSelect } from "antd";
import { DownloadOutlined, FileSearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import apiCaller from "utils/apiCaller";
import OurTable from "./OurTable";
import '../../index.css';
import { Data } from "react-csv/components/CommonPropTypes";
const { Search } = Input;

interface Props {
  params: {
    target: string;
    workflowFile: string;
    colsByStep: Record<string, string[]>;
    showCols: string[];
    searchCols: string[];
    // Removed googleSheetUrl from here
  };
}

interface Column {
  title: string;
  dataIndex: string;
  key: string;
}

const Aggregate = ({ params }: Props) => {
  const {
    target,
    workflowFile,
    colsByStep,
    showCols,
    searchCols,
    // Removed googleSheetUrl from destructuring
  } = params;

  // Define the mapping from workflowFile (or target) to Google Sheets URLs
  const googleSheetUrlMapping: Record<string, string> = {
    "Permission for Undergraduate Students to TA a CSE Course": "https://docs.google.com/spreadsheets/d/1g8xFQYD2bJC6_btL53M7IgBGR41QPVqaUTYLNvM0_WE/edit?usp=sharing",
    "PhD RPE Form": "https://docs.google.com/spreadsheets/d/1NK7RNoYm0JXk1uidMP2b114HQpm4tlcLct5mnVbNsfY/edit?gid=0#gid=0",
    // Add more mappings as needed
  };

  // Determine the googleSheetUrl based on workflowFile
  const googleSheetUrl = googleSheetUrlMapping[workflowFile] || "https://docs.google.com/spreadsheets/d/default_sheet_id/edit?usp=sharing"; // Fallback URL

  const [loading, setLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<Column[]>();
  const [data, setData] = useState<any[]>([]);

  const [searchText, setSearchText] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [showColumns, setShowColumns] = useState(showCols);
  const [showColFilters, setShowColFilters] = useState<string[]>(showCols);
  const allColumns = Object.values(colsByStep).flat();

  const colNameToDataIndex = (colName: string): string => {
    return colName.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  };

  const dataIndexes = searchCols?.filter((col) => showColumns.includes(col)).map((col) => colNameToDataIndex(col));

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    const searchedData = data.filter((row: any) =>
      Object.keys(row).some((key) => {
        if (dataIndexes && dataIndexes.includes(key)) {
          const value = row[key];
          if (typeof value === "string" || typeof value === "number") {
            return `${value}`.toLowerCase().includes(searchValue);
          }
        }
        return false;
      })
    );
    setSearchText(e.target.value);
    setFilteredData(searchedData);
  };

  const createTreeSelectOptions = (columns: string[]) => {
    const options = columns.map((col) => ({
      title: `${col}`,
      value: `${col}`,
    }));

    options.unshift({
      title: "Select All",
      value: "Select All",
    });

    return options;
  };

  const onChange = (selectedValues: string[]) => {
    const isSelectAllSelected = selectedValues.includes("Select All");
    if (isSelectAllSelected) {
      setShowColFilters(["Select All", ...allColumns]);
      setShowColumns(allColumns);
    } else {
      if (showColFilters.includes("Select All")) {
        setShowColFilters([]);
        setShowColumns([]);
      } else {
        setShowColFilters(selectedValues);
        setShowColumns(selectedValues.filter((value) => value !== "Select All"));
      }
    }
  };

  // Updated openGoogleSheet to use the derived googleSheetUrl
  const openGoogleSheet = () => {
    window.open(googleSheetUrl, "_blank");
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const stepFields = Object.fromEntries(
        Object.entries(colsByStep).map(([step, words]) => {
          const processedWords = words.map(colNameToDataIndex);
          return [step, processedWords];
        })
      );
      try {
        const result = await apiCaller.get("/api/aggregate", { params: { target, workflowFile, stepFields } });
        const fetchedData = result.data;
        if (fetchedData) {
          const dataWithKeys = fetchedData.map((item: any, index: number) => ({
            ...item,
            key: `row-${index}-${new Date().getTime()}`,
          }));

          setData(dataWithKeys);
          setFilteredData(dataWithKeys);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Optionally, handle errors here (e.g., set an error state)
      }
      setLoading(false);
    }

    fetchData();
  }, [target, workflowFile, colsByStep]);

  useEffect(() => {
    const tableCols = showColumns.map((col: string) => {
      const convertedCol = colNameToDataIndex(col);
      const defaultColumn = {
        title: col,
        dataIndex: convertedCol,
        key: convertedCol,
      };

			switch (col) {
				case "Created At":
					return {
						...defaultColumn,
						render: (text: any) => {
							return new Date(text._seconds * 1000).toLocaleDateString();
						},
					};
				case "Committee Eval":
					return {
						...defaultColumn,
						render: (text: any) => {
							if (text) {
								return text;
							} else {
								return "In Progress";
							}
						}
					}
				case "Exam Date":
					return {
						...defaultColumn,
						render: (text: any) => {
							return new Date(text[0]).toLocaleDateString();
						}
					}
				default:
					return {
						...defaultColumn,
					};
			}
		});
		setColumns(tableCols);
	}, [showColumns]);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <div className="search-section">
          <div className="sub-search-section">
            {searchCols && (
              <Search
                placeholder={`Search By ${searchCols.join(" / ")}`}
                onChange={handleSearch}
                allowClear={true}
                value={searchText}
              />
            )}
          </div>

					<div className="sub-search-section">
						{showCols && (
							<TreeSelect
								treeData={createTreeSelectOptions(allColumns)}
								value={showColFilters}
								onChange={onChange}
								treeCheckable={true}
								placeholder="Select Columns to Display"
								style={{ width: "100%" }}
								defaultValue={showColumns}
								maxTagCount={2}
								maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
							/>
						)}
					</div>
				</div>

				<Card bordered={false} bodyStyle={{ padding: "0" }}>
					<div id="container" style={{ display: "flex", justifyContent: "space-between", margin: "0 0px" }}>
						{googleSheetUrl && <Button
							type="primary"
							style={{ alignItems: 'center' }}
							shape="round"
							icon={<FileSearchOutlined />}
							onClick={openGoogleSheet}
						>
							View in Google Sheets
						</Button>
						}

            <Button
              type="primary"
              style={{ alignItems: "center" }}
              shape="round"
              icon={<DownloadOutlined />}
            >
              <CSVLink style={{ color: "white", width: "100px" }} data={[allColumns] as Data} filename="template.csv">
                Download CSV Template
              </CSVLink>
            </Button>

            <Button
              type="primary"
              style={{ alignItems: "center" }}
              shape="round"
              icon={<DownloadOutlined />}
            >
              <CSVLink
                style={{ color: "white", width: "100px" }}
                data={filteredData.map((row: any) => {
                  const { key, ...rest } = row;
                  return {
                    ...rest,
                    createdAt: row.createdAt ? new Date(row.createdAt._seconds * 1000).toLocaleDateString() : "",
                  };
                })}
                filename={`${target}.csv`}
                headers={columns?.map((col: any) => ({
                  label: col.title,
                  key: `${col.dataIndex}`,
                }))}
              >
                Download Current User List As CSV
              </CSVLink>
            </Button>
          </div>

					<div style={{ marginTop: "10px", marginLeft: "0px" }}>
						<OurTable columns={columns} data={filteredData} isLoading={loading} />
					</div>
				</Card >
			</div >
		</>
	);
}

export default Aggregate;
