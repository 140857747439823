import {
    Card
} from "antd";

interface Props {
    instructions: string;
}

function WorkflowInstructionBox({ instructions }: Props){
	const instructionsSplit = instructions.split('|');
	const content = instructionsSplit.map((item: any, index: number)=> {
	  return <p key={index}>{item}</p>
	})
    return (
        <Card title="Instructions:" style={{marginBottom: '20px'}}>
			{/* <p>{instructions}</p> */}
			{content}
        </Card>
    );
}

export default WorkflowInstructionBox;