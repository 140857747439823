import { InboxOutlined, ExclamationCircleFilled} from "@ant-design/icons";
import { Form, Select, Upload, DatePicker, Modal, List } from "antd";
import FormBuilder from "antd-form-builder";
import { AuthContext } from "context/AuthProvider";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import apiCaller from "utils/apiCaller";
import SelectSearch from "./Select/SelectSearch";
import MultiSelectSearch from "./Select/MultiSelectSearch";
import SearchDropdown from "./Select/SearchDropdown";
import Loader from "components/Loader";

const { RangePicker } = DatePicker;
interface FormValues {
  [key: string]: any;
}
interface WorkflowFormProps {
  workflowId: string;
  onFinish: any;
  formData: { form: any; formValues?: FormValues };
  params: any;
  isCheck?: boolean;
  workflowType?: string;
  checkUserIncludedRecords? : string
}


const mapOptions = (options: any) => {
  if (!_.isArray(options)) {
    throw new Error("Options should be array in form builder meta.");
  }
  return options.map((opt) => {
    if (_.isArray(opt)) {
      return { value: opt[0], label: opt[1] };
    } else if (_.isPlainObject(opt)) {
      return opt;
    } else {
      return { value: opt, label: opt };
    }
  });
};
//Holds values of select search fields to prevent repeat values.
const selectSearchValues: any = {}
FormBuilder.defineWidget("select-search", SelectSearch, (field: any) => {
  
  if (field.options && !field.children) {
    const handleChange = async (value: any) => {
        //Using the url in the key because this variable persists between workflows. Would use just the workflow id if I could access it, so I used the url (which includes workflow id) instead.
        if(!field.repeatValue)selectSearchValues[`${window.location.href}_${field.key}`]=value
        if (field.verifyField) {
          await verifyUserField(value, field.verifyField);
        }
      }
      //Remove some elements because they persist between workflows. Gets rid of any elements without the current url in the key.
      Object.keys(selectSearchValues).forEach((key) => {
        if (!key.includes(window.location.href)) {
          delete selectSearchValues[key];
        }
      });
    return {
      ...field,
      onChange: handleChange,
      children: mapOptions(field.options).map((opt) => {
        //Prevents repeat values by checking if the value is in the select search object. If it is, it won't return the corresponding select element. That value won't be available in other select searches.
        if(field.repeatValue==undefined||field.repeatValue||Object.keys(selectSearchValues).length==0||!Object.values(selectSearchValues).includes(opt.email)){
          return (
            <Select.Option value={opt.email} key={opt.email}>
              {opt.name} - {opt.email}
            </Select.Option>
          );
        }
      }),
    };
  }
  return field;
});

FormBuilder.defineWidget("select-backend-search", SearchDropdown, (field: any) => {
  const functions = {
    handleOptionSelect: field.handleOptionSelect,
    fetchOptions:field.fetchOptions
  };
  return {
    ...field,
    children:functions
  };
});
FormBuilder.defineWidget("multi-select-search", MultiSelectSearch, (field: any) => {
  if (field.options && !field.children) {
    return {
      ...field,
      children: mapOptions(field.options),
    };
  }
  return field;
});

FormBuilder.defineWidget('list', ({items = [], headerData }:{items: any[], headerData: string}) => {
  return (
    <List
      bordered
      header={<div>{headerData}</div>}
      dataSource={items}
      renderItem={(item: any) => (
        <List.Item>
          <li style={{ listStyleType: 'disc', marginLeft: '20px' }}>
            {item.firstName} {item.lastName}
          </li>
        </List.Item>
      )}
    />
  );
});


const verifyUserField = async (email: string, field: string)=> {
  try {
    // Make an async backend call to verify if the user already has an advisor
    const res = await apiCaller.get("/api/user", {
      params: { email: email },
    });
    const userObj = res.data;
    // Check if the response meets the required condition
    if (field.includes(":")) {
      const [userField, userSubField] = field.split(":");
      let userFieldValue;
      if (userObj?.[userField]?.[userSubField].includes("@")) {
        const res = await apiCaller.get("/api/user", {
          params: { email: userObj?.[userField]?.[userSubField] },
        });
        let fullName=res.data?.firstName+" "+res.data?.lastName
        userFieldValue = res.data?.name ? res.data?.name : fullName;
      }
      if (userObj?.[userField]?.[userSubField]) {
        Modal.confirm({
          title: 'Confirmation',
          icon: <ExclamationCircleFilled />,
          content: `Warning! ${userObj.firstName} ${userObj.lastName} already has an ${userField} - ${userFieldValue}. Do you still want to continue?`,
          okText: 'OK',
          okType: 'danger',
          okButtonProps: {
            style: { backgroundColor: 'red', borderColor: 'red', color: 'white' }, // Custom red color for OK button
          },
          cancelText: 'Cancel'
        });
      }
    } else {
      if (userObj?.[field]) {
        Modal.warning({
          title: 'Confirmation',
          content: `The student's current ${field} is ${userObj?.[field]}. Do you want to continue ?`,
        });
      }
    }
  } catch (error) {
    console.error('Error during backend call:', error);
  }
}

const dummyRequest = ({ file, onSuccess }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const FILE_SIZE_LIMI=20
const validateSize = (sizeVal: any) => {
  console.log("The sizeVal is: ", sizeVal / Math.pow(10, 6));
  if (sizeVal / Math.pow(10, 6) < FILE_SIZE_LIMI) {
    return "valid";
  } else {
    return "invalid";
  }
};

const FormUploadButton = ({ fileCount }: any) => {
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  return (
    <Form.Item
      name="dragger"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      noStyle
      rules={[
        {
          required: true,
          message: "Please upload a file!",
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            console.log("The file value is: ", value);
            if (value.length > 0 && validateSize(value[0].size) === "valid") {
              return Promise.resolve();
            } else if (value.length === 0) {
              return Promise.reject();
            } else {
              return Promise.reject(new Error("The file uploaded exceeds the limit of "+FILE_SIZE_LIMI+" MB!"));
            }
          },
        }),
      ]}
    >
      <Upload.Dragger name="file" maxCount={fileCount} customRequest={dummyRequest}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
      </Upload.Dragger>
    </Form.Item>
  );
};

FormBuilder.defineWidget("file-upload", FormUploadButton);

FormBuilder.defineWidget("range-picker", RangePicker);

const WFForm = ({ workflowId, formData, onFinish, params, isCheck, workflowType }: WorkflowFormProps) => {
  const { activeUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const forceUpdate = FormBuilder.useForceUpdate();
  const [meta, setMeta] = useState(null); // Complete form data
  const [viewMeta, setViewMeta] = useState(null); 
  const [uploadError, setUploadError] = useState(false);
  const { type, tableStep, editable, editAccess, checkUserIncludedRecords } = params;
  const [disableEmailSbuId,setDisableEmailSbuId]=useState(true);
  const [selectedOption, setSelectedOption] = useState<{ value: string; label: string; sbuid: string ,email:string} | null>(null);
  
  useEffect(() => {
    setLoading(true)
    if (selectedOption) {
      const emailValue = selectedOption.email || "";
      form.setFieldsValue({name:selectedOption.value})
      let disabledTF=!(!selectedOption?.email || selectedOption.email=="" )
      setDisableEmailSbuId(disabledTF)
      // console.log("disabledTF",disabledTF,selectedOption)
      form.setFieldsValue({sbuId:selectedOption.sbuid})
      form.setFieldsValue({email:selectedOption.email})
    }
    setLoading(false)
  }, [selectedOption]);

  const formattedDate = (dateTimeString: string) => {
    const date = new Date(dateTimeString);

    const options: any = {
      timeZone: "America/New_York",
      weekday: "long",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    // Format the output based on the Eastern Time Zone
    const formattedDate = date.toLocaleString("en-US", options);

    return formattedDate;
  };
  const handleOptionSelect = (option: { value: string; label: string; sbuid: string,email:string }) => {
   setSelectedOption(option)
  };
  const fetchOptions = async (searchValue: string) => {
    const res = await apiCaller.get("/api/search", {
      params: { queryText: searchValue },
    });
    return res.data.map((d: any) => ({
      value: `${d.firstName} ${d.lastName} - ${d.email}`,
      label: `${d.firstName} ${d.lastName} - ${d.email}`,
      email:d.email,
      sbuid: d.sbuId,
    }));
  };
  const updateMeta = async (meta: any, values?: FormValues, user?: any) => {
    let newMeta = { ...meta };
    let newViewMeta = { ...meta };
    const previousFormKeys: any[] = [];
    const singleInstanceFormKeys: any[] = [];
    newMeta.fields = await Promise.all(
      newMeta.fields.map(async (field: any) => {

        if (field.hidden){
          field.widgetProps={style:{ visibility:'hidden' ,width: '0px',  height:'0px'}}
        }
        if (isCheck && !editable) {
          field.disabled = true;
        }
        if (field.pattern && typeof field.pattern === "string") {
          field.pattern = new RegExp(field.pattern.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
        }
        if (field.rules) {
          field.rules.map((rule: any) => {
            if (rule.pattern) {
              rule.pattern = new RegExp(rule.pattern);
            }
            return field;
          });
        }
        let formUser = type === "update_user" ? user : activeUser;
        if (field.key === "name") {
          field.initialValue = formUser?.firstName+" "+formUser?.lastName;
          if (!isCheck && !editable && !activeUser.roles.includes(editAccess)) {
            field.disabled = true;
          } else if (activeUser.roles.includes(editAccess)) {
            field.initialValue = "";
          }
        }
        if (field.key === "sbuId") {
          if (field.initialValue!="") {
            field.initialValue = formUser?.sbuId || activeUser.sbuId ; // order important as left to right
          }
          if (!isCheck && !editable && !activeUser.roles.includes(editAccess)) {
            field.disabled = true;
          }
          
          if (meta.formName ==="keyCardAccessRequest"){

            field.disabled=disableEmailSbuId;
            if (activeUser.roles.includes("faculty")){
              field.initialValue ="";
            }
          }
        }

        if (field.options === "coursecode") {
          try {
            // Fetch the course data from the /courses API
            const res = await apiCaller.get("/api/courses");
        
            // Set `courseCode` only for the "coursecode" field
            const courseCodeOptions = res.data.map((course: any) => ({
              value: course.courseCode,  // Use `courseCode` as the option value
              label: course.courseCode,   // Display `courseCode` only
            }));
        
            // Apply courseCodeOptions to the "Computer Science Course to Attend" field
            field.options = courseCodeOptions;
          } catch (error) {
            console.error("Error fetching course options:", error);
            field.options = []; // Set to an empty array if there’s an error
          }
        }
        
        if (field.options === "coursecodeug") {
          try {
            // Fetch the same course data
            const res = await apiCaller.get("/api/courses");
            
            // 1. Filter out only undergrad courses (where numeric part < 500)
            const undergradOnly = res.data.filter((course: any) => {
              // Extract numeric portion from something like "CSE 508" or "CSE115"
              const numericMatch = course.courseCode.match(/\d+/);
              const numericValue = numericMatch ? parseInt(numericMatch[0], 10) : 9999;
              return numericValue < 500;  // Only keep courses < 500
            });
        
            // 2. Sort the filtered list by the numeric portion, ascending
            undergradOnly.sort((a: any, b: any) => {
              const matchA = a.courseCode.match(/\d+/);
              const matchB = b.courseCode.match(/\d+/);
              const numA = matchA ? parseInt(matchA[0], 10) : 9999;
              const numB = matchB ? parseInt(matchB[0], 10) : 9999;
              return numA - numB; // ascending order
            });
        
            // 3. Map to the desired { value, label } objects
            const courseCodeOptions = undergradOnly.map((course: any) => ({
              value: course.courseCode,
              label: `${course.courseCode} - ${course.courseName}`,
            }));
            
            // Apply to the "coursecodeug" field
            field.options = courseCodeOptions;
          } catch (error) {
            console.error("Error fetching undergrad course options:", error);
            field.options = [];
          }
        }
        

        if (field.options === "coursetitle") {
          try {
            // Fetch the course data from the /courses API
            const res = await apiCaller.get("/api/courses");
        
            // Set `courseName` only for the "coursetitle" field
            const courseTitleOptions = res.data.map((course: any) => ({
              value: course.courseName,   // Use `courseName` as the option value
              label: course.courseName,   // Display `courseName` only
            }));
        
            // Apply courseTitleOptions to the "Course Title" field
            field.options = courseTitleOptions;
          } catch (error) {
            console.error("Error fetching course options:", error);
            field.options = []; // Set to an empty array if there’s an error
          }
        }
        
        if (field.key === "firstName") {
          field.initialValue = formUser?.firstName;
          if (!isCheck && !editable) {
            field.disabled = true;
          }
        }
        if (field.key === "lastName") {
          field.initialValue = formUser?.lastName;
          if (!isCheck && !editable) {
            field.disabled = true;
          }
        }
        if (field.key.toLowerCase() === "email") {
          field.initialValue = formUser?.email;
          if (!isCheck && !editable) {
            field.disabled = true;
          }
          if (meta.formName ==="keyCardAccessRequest"){
            if (activeUser.roles.includes("faculty")){
              field.initialValue ="";
              }
              console.log("setting meta disableEmailSbuId",disableEmailSbuId)
              field.disabled=disableEmailSbuId;
          }
        }
        // console.log("updateMeta  after",formUser)
        if (field.key.toLowerCase() === "sbuemailaddress") {
          field.initialValue = formUser?.sbuEmail ? formUser?.sbuEmail : formUser?.email;
          if (!isCheck && !editable && !activeUser.roles.includes(editAccess)) {
            field.disabled = true;
          } else if (activeUser.roles.includes(editAccess)) {
            field.initialValue = "";
          }
        }
        if (field.key.toLowerCase() === "alternateemailyn") {
          // in viewUserSettings Form
          field.initialValue = formUser?.alternateEmailAsPrimary?.toLowerCase() == "n" ? "No" : "Yes";
        }
        if (field.key.toLowerCase() === "alternateemail" && formUser && formUser.alternateEmailAsPrimary) {
          // in updateUser Form switch
          field.initialValue = formUser?.alternateEmailAsPrimary?.toLowerCase() == "n" ? false : true;
        }
        if (field.key.toLowerCase() === "alternateemailaddress") {
          // in both viewUserSettings and updateUser
          field.initialValue = formUser?.sbuEmail ? formUser?.sbuEmail : "";
        }
        if (field.key.toLowerCase() === "phonenumber") {
          // in both viewUserSettings and updateUser
          field.initialValue = formUser?.phoneNumber ? formUser?.phoneNumber : "Not provided";
        }
        if (field.key.toLowerCase() === "title" && workflowType=="single-instance") {
          // currently used in both viewUserSettings and updateUser
          field.initialValue = formUser?.title ? formUser?.title : "Not provided";
        }
        if (/departmentProgram/i.test(field.key)) {
          field.initialValue = "CSE";
          if (!isCheck && !editable && !activeUser.roles.includes(editAccess)) {
            field.disabled = true;
          }
        }
        if (field.key === "roles") {
          field.initialValue = formUser?.roles;
          // if (!isCheck && !editable) {
          //   field.disabled = true;
          // }
        }
        if (field.key === "impRoles") {
          field.initialValue = formUser?.impRoles;
          // if (!isCheck && !editable) {
          //   field.disabled = true;
          // }
        }
        if (field.key.startsWith("form:")) {
          previousFormKeys.push(field.key);
        } else if (field.key.includes("label")) {
          field.render = () => (
            <fieldset>
              <legend>{field.legend}</legend>
            </fieldset>
          );
        }
        if (field.formKey?.startsWith("form:")) {
          singleInstanceFormKeys.push(field.formKey);
        }
        // TODO: The component seems to Re-render twice. This must be fixed.
        // The typeof condition is a temporary fix for handling the Re-rendering of the Component.
        if (field.widget === "select-search" && Array.isArray(field.options) && typeof field.options[0] !== "object") {
          const promises = field.options.map(async (item: string) => {
            const res = await apiCaller.get("/api/usersByRole", {
              params: { role: item },
            });
            return res.data;
          });

          const results = await Promise.all(promises);
          const mergedResults = _.uniqBy(results.flat(), "email");
          const emailVals = mergedResults.map((d: any) => ({
            name: d?.firstName+" "+d?.lastName,
            email: d.email,
          }));

          field.options = emailVals;
        }
        if (field.widget === "select-search" && activeUser.roles.includes(editAccess)) {
          field.initialValue = `${activeUser.email}`;
          field.disabled = true;
        }
        if (field.widget==="select-backend-search" ) {
          //field.initialValue =  `${activeUser.name} - ${activeUser.email}`
          if (!activeUser.roles.includes(editAccess)){
                field.disabled=true
          }

        }
        if (field.options === "faculty") {
          const res = await apiCaller.get("/api/usersByRole", {
            params: { role: "faculty" },
          });
          const facultyEmails = res.data
            .map((d: any) => ({
              name: d?.firstName+" "+d?.lastName,
              email: d.email,
            }))
            .sort((a: any, b: any) => a.name.localeCompare(b.name));
          field.options = facultyEmails;
        }else if (field.options === "users") {
          field.handleOptionSelect=handleOptionSelect;
          field.fetchOptions=fetchOptions
        }
        if (field.widget === "date-picker") {
          if (field.dateProps) {
            const { defaultValue } = field.dateProps;
            if (defaultValue === "Next Year") {
              field.initialValue = moment().add(1, "years");
            }
          }
        }
        if (field.options === "workflowNameforDotty") {
          const res = await apiCaller.get("/api/all-workflow-steps");
          const WFData = res.data.map((d: any) => ({
            name: d.section,
            email: d.id,
          }));
          field.options = WFData;
        }
        return field;
      })
    );

    if (checkUserIncludedRecords) {
      try {
        const apiResponse = await apiCaller.get('/api/get-user-included-records', {
          params: { userProps: checkUserIncludedRecords, email: activeUser.email }
        });
        const data = apiResponse.data;

        if (data.length > 0) {
          // Based on the API result, create a new field
          const newField = {
            key: 'advisorList',
            widget: 'list',
            widgetProps: {
              headerData: 'The following are the list of students who are being advised by you',
              items: data,
            }
          };
          
          // Add the new field at the front of the fields array
          newMeta.fields.unshift(newField); 
        
        }

      } catch (error) {
        console.error("Error fetching data for the new field:", error);
      }
    }

    if (type === "update_form" && workflowType === "single-instance") {
      // Check if there is already a document stored in the Database related to the User
      // of the single instance workflow.
      const res = await apiCaller.get("/api/verify-workflow-exists", {
        params: { id: workflowId },
      });
      if (res.data.workflowExists) {
        const res = await apiCaller.put("/api/form-values", {
          workflowId: workflowId,
          formKeys: singleInstanceFormKeys,
        });
        if (res.data) {
          newMeta.fields.forEach((field: any) => {
            if (field.formKey in res.data && !field.omitReFilling) {
              field.initialValue = res.data[field.formKey];
            }
          });
        }
      }
    }
   
    if (previousFormKeys.length > 0) {
      // TODO: Find where this if block is used
      const res = await apiCaller.put("/api/form-values", {
        workflowId: workflowId,
        formKeys: previousFormKeys,
      });
      if (res.data) {
        newViewMeta.fields = newMeta.fields.filter((field: any) => {
          return field.key in res.data || field.key.includes("form:");
        });
        newMeta.fields = newMeta.fields.filter((field: any) => {
          return !previousFormKeys.includes(field.key);
        });
        newViewMeta.fields.map((field: any) => {
          if (field.key in res.data || field.key.includes("form:")) {
            if (res.data[field.key]) {
              // The below check is for retrieving just the start time and date from the range picker
              if (Array.isArray(res.data[field.key]) && moment(res.data[field.key][0]).isValid()) {
                const date = formattedDate(res.data[field.key][0]);
                field.initialValue = date;
              } else {
                field.initialValue = res.data[field.key];
              }
            } else {
              field.initialValue = "N/A";
            }
            if (field.options) {
              let resemail = res.data[field.key];
              for (let { name, email } of field.options) {
                if (email === resemail) {
                  field.initialValue = name + ` (${email})`;
                  break;
                }
              }
            }
          }
          return field;
        });
        setViewMeta(newViewMeta);
      }
    } else {
      setViewMeta(null);
    }

    let metaClone = newMeta;
    if (values && Object.keys(values).length > 0) {
      metaClone = _.cloneDeep(newMeta);
      metaClone.fields=metaClone.fields.filter((field: any) => {
        return !field.hidden ;
      });
      let populatedForm = metaClone.fields;
      for (let [key, value] of Object.entries(values)) {
        let index;
        if ((index = populatedForm.findIndex((f: any) => f.key === key)) !== -1) {
          if (populatedForm[index].widget === "date-picker") {
            populatedForm[index].initialValue = moment(value);
          } else if (populatedForm[index].widget === "range-picker") {
            if (Array.isArray(value) && isCheck) {
              const date = formattedDate(value[0]);
              populatedForm[index].initialValue = date;
            } else if (Array.isArray(value)) {
              const dates = value.map((item) => moment(item));
              populatedForm[index].initialValue = dates;
            }
          } else {
            populatedForm[index].initialValue = value;
            if (populatedForm[index].options) {
              let resemail = value;
              for (let { name, email } of populatedForm[index].options) {
                if (email === resemail) {
                  populatedForm[index].initialValue = isCheck ? name + ` (${email})` : `${email}`;
                  break;
                }
              }
            }
          }
        }
      }
    }
    setMeta(metaClone);
  };

  useEffect(() => {
    async function getTableStep() {
      const res = await apiCaller.get("/api/table-step", {
        params: { workflowId, tableStep },
      });
      const data = res.data;
      if (data && data.length === 1) {
        if (formData.formValues) {
          // Load existing form values
          await updateMeta(formData.form, formData.formValues, data[0]);
        } else {
          // New form
          await updateMeta(formData.form, {}, data[0]);
        }
      }
    }
    async function updateFormMeta() {
      if (formData.formValues) {
        // Load existing form values - prefilled form
        await updateMeta(formData.form, formData.formValues);
      } else {
        // New form
        await updateMeta(formData.form);
      }
    }
    setLoading(true)
    if (tableStep) {
      getTableStep().then(()=>{
        setLoading(false);
      })
    } else {
      updateFormMeta().then(()=>{
        setLoading(false);
      })
    }
  }, [disableEmailSbuId]);

  // if (loading) {
  //   return null;
  // }
  const filteredFields = (viewMeta as any)?.fields?.filter((field: any) => !field.hidden);

  return (
    <>
      {loading ? (
          <Loader />
        ) : uploadError ? (
        <p>There was an error uploading the file. Please DO NOT proceed. Kindly contact the administrator.</p>
      ) : (
        <Form
          form={form}
          onValuesChange={() => {
            forceUpdate();
          }}
          layout="vertical"
          id="workflow-form"
          onFinish={async (e) => {
            let error = false;
            if (e?.dragger !== undefined) {
              const file = e.dragger[0].originFileObj;
              const formData = new FormData();
              formData.append("file", file);
              formData.append("workflowId", workflowId);
              await apiCaller
                .post("/api/upload-file", formData)
                .then((res) => {
                  return res;
                })
                .catch((err) => {
                  error = true;
                  setUploadError(true);
                });
              delete e.dragger;
            }
            if (!error) {
              onFinish(e);
            }
          }}
        >
          {viewMeta && (
            <fieldset>
              <FormBuilder form={form} meta={filteredFields} viewMode />
            </fieldset>
          )}
          <fieldset>
            {viewMeta && <legend />}
            <FormBuilder meta={meta} form={form} viewMode={isCheck} />
          </fieldset>
        </Form>
      )}
    </>
  );
};

export default WFForm;
